import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import BlogSidebar from '../components/BlogSidebar';
import BlogPostSummary from '../components/BlogPostSummary';
import { cleanTitle, cleanExcerpt, getFeaturedImage, formatDate } from '../utils/postUtils';

import styles from './blogList.module.scss';

const BlogCategoryList = ({ data, pageContext }) => {
  const backgroundHeaderImage =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108997/bg-blog_gewlx8.jpg';

  const { name, slug, currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '' : _.toString(currentPage - 1);
  const nextPage = _.toString(currentPage + 1);

  const posts = data.allWordpressPost.edges;
  return (
    <Layout>
      <SEO
        title={`${_.startCase(name)}${
          currentPage > 1 ? ` - Page ${currentPage}` : ''
        } | Mindset Family Therapy`}
        pathname={
          currentPage < 2 ? `/blog/category/${slug}` : `/blog/category/${slug}/${currentPage}`
        }
        description={`Read the latest blog posts within the ${name} category from Mindset Family Therapy, a specialized treatment practice for OCD and related anxiety disorders in Utah.`}
      />
      <PageHeader pageCategory="Mindset" pageName="Blog" headerImage={backgroundHeaderImage} />
      <div className={styles.blogWrapper}>
        <div className={styles.postsWrapper}>
          <div className={styles.categoryWrapper}>
            <div className={styles.standardText}>Posts filed under category</div>
            <div className={styles.tag}>
              <p>{_.toUpper(name)}</p>
            </div>
          </div>

          {_.map(posts, ({ node }) => {
            const { id, slug: postSlug, title, content, date } = node;
            const featuredImage = _.get(
              node,
              'featured_media.localFile.childImageSharp.fixed',
              null,
            );

            let backupImage = getFeaturedImage(content);

            if (_.isNil(featuredImage) || _.isNil(backupImage)) {
              backupImage =
                'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497110189/flowers_b9l2ed.jpg';
            }

            return (
              <BlogPostSummary
                key={id}
                title={cleanTitle(title)}
                date={formatDate(date)}
                image={featuredImage}
                backupImage={backupImage}
                excerpt={cleanExcerpt(content)}
                slug={postSlug}
              />
            );
          })}
          <div className={styles.pageNavWrapper}>
            {!isFirst && (
              <Link to={`/blog/category/${slug}/${prevPage}`}>
                <div className={styles.pageNavLink}>&larr;</div>
              </Link>
            )}
            {!isLast && (
              <Link to={`/blog/category/${slug}/${nextPage}`}>
                <div className={styles.pageNavLink}>&rarr;</div>
              </Link>
            )}
          </div>
          <div className={styles.pageIdent}>
            Page {currentPage} of {numPages}
          </div>
        </div>
        <BlogSidebar />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query blogCategoryPageQuery($name: String!, $skip: Int!, $limit: Int!) {
    allWordpressPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { elemMatch: { name: { eq: $name } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          slug
          title
          content
          date
          categories {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fixed(height: 200, width: 200) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

BlogCategoryList.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default BlogCategoryList;
